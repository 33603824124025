<template>
  <v-row justify="center" class=" pb-3" no-gutters>
    <v-col cols="12" md="8" sm="12">


      <!-- Slide Group Content -->
      <v-slide-group :draggable="true">
        <v-slide-item v-for="(value, index) in banners" :value="index" :key="index">
          <v-card @click="$router.push({ path: value.hero_banner.link })" elevation="4" :image="value.hero_banner.src"
            height="250" class="mx-2 my-4" tile>
            <v-card-title>{{ value.title }}</v-card-title>
            <v-card-subtitle>{{ value.hero_banner.caption }}</v-card-subtitle>
          </v-card>
        </v-slide-item>
      </v-slide-group>

    </v-col>

  </v-row>
</template>

<script>
import { ref, onMounted, watchEffect, onUnmounted, watch, nextTick } from 'vue';
export default {
  props: {
    banners: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    tab: null,
  }),
  setup(props) {
    // const carouselRef = ref(null);


    onMounted(() => {


    });
    // Watch banners to update the subtitle class
    watchEffect(() => {

    });
    const aia_health_tabs = ref(0); // Default active tab

    return {
      aia_health_tabs,


    }

  },
  // props: {
  //   banners: {
  //     type: Array,
  //     // Arrays and Objects must return factory functions instead of
  //     // literal values. You can't do `default: []`, you have to do
  //     // `default: function() { return []; }`... what I wrote was the 
  //     // short-hand for how to do it with ES6 fat-arrow functions
  //     default: () => ([]) 
  //   }
  // }
}

</script>

<style></style>