<template>


  <v-container fluid class="pa-0">
    <v-row justify="center" class=" pa-3" no-gutters>
      <v-col cols="12" md="6" sm="12">

        <transition name="scroll-right" appear>
          <h1 class="text-primary">AIA Health Happy Kids | ประกันสุขภาพเด็ก</h1>
        </transition>
        <transition name="scroll-right" appear>
          <h2 class="">เพราะอนาคตของลูก เริ่มต้นด้วยสุขภาพที่ดี เพิ่มความอุ่นใจด้วย <b class="text-primary"> AIA Health Happy Kids </b></h2>
        </transition>

        <v-img src="/aia-health-happy-kids-page/img-1.jpg"/>



       
      
       <h2 class="text-primary">วางแผนเรื่องสุขภาพของลูกด้วยประกันสุขภาพแบบเหมาจ่าย</h2>
       <p>ประกันสุขภาพแบบเหมาจ่ายจะให้วงเงินในการเคลมค่อนข้างสูงตั้งแต่หลักแสนถึงหลักล้าน ช่วยเหมาจ่ายค่ารักษาให้หลายรายการ อาจมีการจำกัดวงเงินเพียงบางรายการและขึ้นอยู่กับกรมธรรม์ที่เลือก รูปแบบการจ่ายผลประโยชน์จึงเข้าใจง่าย ไม่ซับซ้อน เข้าโรงพยาบาลแต่ละครั้งก็สะดวกสบาย ไม่ต้องสำรองจ่าย ดังนั้น การมีหลักประกันสุขภาพที่ครอบคลุมจึงเป็นทางเลือกที่ช่วยให้มั่นใจได้ว่าการเข้าโรงพยาบาลแต่ละครั้งจะป้องกันภาระค่าใช้จ่ายให้เราได้
ตัวช่วยในการดูแลสุขภาพของลูกรักอย่าง AIA Health Happy Kids ที่จะช่วยแบ่งเบาภาระค่าใช้จ่ายให้พ่อแม่คลายความกังวล ด้วยความคุ้มครองแบบเหมาจ่าย</p>
<h2 class="text-primary">ข้อควรพิจารณาในการเลือกประกันสุขภาพเด็ก</h2>

       <v-list tag='ul'>
        <v-list-item tag='li'>- วงเงินคุ้มครองค่ารักษาพยาบาล</v-list-item>
        <v-list-item tag='li'>- ครอบคลุมค่าห้องพักและค่าอาหาร</v-list-item>
        <v-list-item tag='li'>- ความคุ้มครองโรคเฉพาะทางหรืออุบัติเหตุ</v-list-item>
        <v-list-item tag='li'>- ค่าเบี้ยประกันที่เหมาะสมกับงบประมาณครอบครัว</v-list-item>
        
        </v-list>
        <p>การทำประกันสุขภาพให้ลูกจึงเป็นการลงทุนในสุขภาพและอนาคตของลูกน้อย และยังช่วยลดภาระทางการเงินของครอบครัวได้ในระยะยาว</p>
        <h2>จุดเด่นแผนประกันสุขภาพเหมาจ่ายเด็ก <b class="text-primary">AIA HEALTH HAPPY KIDS</b></h2>
        <v-img src="/aia-health-happy-kids-page/img-2.webp"/>
        <v-img src="/aia-health-happy-kids-page/img-3.webp"/>


        <h2>ตารางความคุ้มครอง <b class="text-primary">AIA Health HAPPY KIDS</b></h2>
        <v-img src="/aia-health-happy-kids-page/img-4.webp"/>
        <v-img src="/aia-health-happy-kids-page/img-5.webp"/>

        <h2>3 เหตุผลที่ควรทำประกันให้ลูก</h2>
        <p>สำหรับคุณพ่อคุณแม่หรือคนที่กำลังวางแผนจะมีลูก หลายครอบครัวอาจกำลังคิดเรื่องประกันเพื่อคุ้มครองลูก เนื่องจากมีความกังวลใจทั้งในแง่ของสุขภาพและความปลอดภัยในชีวิตของลูก แต่ในขณะเดียวกันก็มีความคิดว่าเมื่อทำไปแล้วจะคุ้มค่าหรือไม่ เพราะคุณพ่อคุณแม่ต่างก็มั่นใจในการดูแลลูกของตัวเองให้ปลอดภัยและมีสุขภาพดีได้ ถ้าไม่ได้ป่วยหนักจนต้องเข้าโรงพยาบาลหรือบาดเจ็บก็กลัวว่าจะต้องจ่ายประกันไปแบบสูญเปล่า แต่เหล่าคุณพ่อคุณแม่รู้หรือไม่ว่าแท้จริงแล้ว การทำประกันให้ลูกนั้นจะได้อะไรมากกว่าที่คิด กับ 3 เหตุผลที่พ่อแม่ควรทำประกันให้ลูกรัก</p>
        <v-list tag='ul'>
        <v-list-item tag='li'><b>1.เพราะเด็กเล็กไม่สามารถสื่อสารได้ จึงควรคุยกับแพทย์ผู้เชี่ยวชาญ </b></v-list-item>
        <p>ยิ่งในเด็กแรกเกิดจนถึงช่วงวัยก่อนเข้าโรงเรียน บางครั้งลูกเกิดเจ็บป่วยขึ้นมา มีอาการปวดหัว ปวดท้อง เด็กจะไม่สามารถสื่อสารกับพ่อแม่ เพื่อบอกถึงความเจ็บปวดของอาการป่วยนั้นได้ ทำได้เพียงเปล่งเสียงร้องไห้ออกมาและอาจจะมีการแสดงออกผ่านท่าทางทางกายภาพ ทำให้ยากต่อการคาดเดา ส่งผลให้คุณพ่อคุณแม่ต้องพาลูกไปตรวจและวินิจฉัยอาการที่โรงพยาบาล ซึ่งอาการเล็กน้อยเหล่านี้ อาจจะไม่ใช่ค่าใช้จ่ายที่สูงมากต่อครั้งแต่ก็มักจะมีโอกาสเกิดขึ้นได้บ่อย โดยเฉลี่ยแล้วใน 1 ปีจะต้องมีการเข้าโรงพยาบาล 2-3 ครั้ง หากเป็นผู้ป่วยนอกค่าใช้จ่ายต่อครั้งจะอยู่ที่ประมาณ 1,200 - 3,000 บาท หรือหากต้องนอนโรงพยาบาลเป็นผู้ป่วยในค่าใช้จ่ายจะอยู่ที่ประมาณ 10,000 - 20,000 บาทแล้วแต่โรคหรืออาการ</p>
        <v-list-item tag='li'><b>2. เด็กเล็กถ้ามีอาการป่วยหนัก จะต้องนอนโรงพยาบาลหลายวัน </b></v-list-item>
        <p>โดยเฉพาะในยุคปัจจุบันที่เชื้อโรคหรือไวรัส มีการพัฒนาความรุนแรงและระบาดเร็วมากขึ้น ทำให้เมื่อลูกรักมีอาการป่วยหนัก มักจะมีอาการที่รุนแรง จึงมีความจำเป็นที่จะต้องนอนโรงพยาบาลเพื่อเฝ้าดูอาการอย่างน้อย 3-7 วัน เพราะในวันแรกต้องเริ่มสังเกตอาการและเริ่มเก็บผลการรักษาเพื่อนำไปวินิจฉัย วันที่สองเริ่มให้ยาและสังเกตการตอบสนองการรักษา ถ้าอาการดีขึ้นในวันที่สามแพทย์จึงจะอนุญาตให้กลับบ้านได้ แต่ในบางโรคที่ต้องมีการรักษาแบบประคับประคองอาการ เช่น โรคปอดติดเชื้อ ต้องมีการสังเกตอาการนานหลายวัน ค่าใช้จ่ายจึงสูงตามไปด้วย</p>
        <p>สำหรับค่าใช้จ่ายในการรักษาเด็กเล็กในโรงพยาบาลเอกชนอยู่ที่ประมาณ 10,000 - 20,000 บาท ซึ่งอาจทำให้คุณพ่อคุณแม่ต้องแบกรับค่าใช้จ่ายสูง</p>
        <v-list-item tag='li'><b>3. ร่างกายเด็กมีความบอบบาง ติดเชื้อง่ายและมีโอกาสเป็นซ้ำ</b></v-list-item>
        <p>เพราะภูมิคุ้มกันเด็กยังไม่แข็งแรง เนื่องจากอยู่ในช่วงวัยที่กำลังมีการพัฒนา จึงสามารถติดเชื้อได้ง่าย เมื่อติดแล้วก็มีโอกาสที่จะเป็นซ้ำได้ และในครั้งที่สองอาจจะมีความรุนแรงมากกว่าครั้งแรก จึงต้องระวังเป็นพิเศษ เนื่องจากอาการรุนแรงขึ้นค่าใช้จ่ายก็จะสูงขึ้นตามไปด้วย</p>
        <p>จะเห็นได้ว่าการมีประกันสุขภาพสำหรับลูกรักจึงเป็นเรื่องที่สำคัญ เพราะลูกอาจไม่ได้อยู่ในความดูแลของพ่อแม่ตลอดเวลา ทำให้มีโอกาสติดมาจากเพื่อนที่โรงเรียนได้ ดังนั้นพ่อแม่จึงควรมีตัวช่วยในการดูแลสุขภาพของลูกรักอย่าง AIA H&S EXTRA ที่จะช่วยแบ่งเบาภาระค่าใช้จ่ายให้พ่อแม่คลายความกังวลได้</p>
      </v-list>
        <h2>ราคา ค่าเบี้ยประกันสุขภาพเด็ก <b class="text-primary">AIA Health HAPPY KIDS</b></h2>
        <v-img src="/aia-health-happy-kids-page/img-6.webp"/>

        
        
       
         <v-row class="mb-5">
      <v-col justify="center" class="text-center" cols="12"><v-btn color="primary"  @click="navigateToCalPremium">
          คำนวณเบี้ยประกัน
          คลิกเลย
        </v-btn></v-col>
    </v-row>

      </v-col>
    </v-row>

   

   
  </v-container>

  <v-snackbar v-model="snackbar" :timeout="3000" top color="primary">
    {{ snackbarMessage }}
    <v-btn color="secondary" text @click="snackbar = false">ปิด</v-btn>
  </v-snackbar>
</template>

<script>
import { ref, onMounted,onUnmounted, watch, nextTick } from 'vue';
import axios from '../plugins/axios'; // Import the custom axios instance
import logoPath from '../assets/logo.png';


export default {
  mounted() {
    document.title = "AIA Prime One"
    const metaTags = [
      { property: "og:title", content: "AIA Prime One" },
      { property: "og:description", content: "AIAPrimeONE เป็นตัวแทนประกันที่พร้อมจะดูแลและปกป้องคุณและครอบครัวในทุกสถานการณ์" },
      { property: "og:url", content: "https://aiaprimeone.com" },
      { property: "og:type", content: "website" }
    ];

    metaTags.forEach(tag => {
      const metaElement = document.createElement("meta");
      metaElement.setAttribute("property", tag.property);
      metaElement.setAttribute("content", tag.content);
      document.head.appendChild(metaElement);
    });
  },
  setup() {

    const drawer = ref(false);
    const snackbar = ref(false); // Controls the visibility of the snackbar
    const snackbarMessage = ref(''); // Holds the message to display in the snackbar
    const target = ref(null);
    const appBarHeight = 64;
    


  
    onMounted(() => {
    });
    onUnmounted(() => {
    });


    return {
      drawer,
      snackbar,
      snackbarMessage,
      appBarHeight
    };
  },
  computed: {
  },
  data() {
    return {
      logo: logoPath,
      // banners1: [
      //   {
      //     src: '/banner-master.jpg',
      //     // title: 'Welcome to our site',
      //     // description: 'This is the first banner description',
      //   },
      //   {
      //     src: '/banner-agent.jpg',
      //     // title: 'Discover our products',
      //     // description: 'This is the second banner description',
      //   },

      // ]

    };

  },

  methods: {
    navigateToCalPremium() {
      this.$router.push('/cal-premium'); // Ensure your router is set up for this path
    },
  }
};
</script>


<style scoped>
.v-card {
  margin-bottom: 1rem;
}

.header-cell {
  height: 200px;
  /* Set a fixed height for each header cell */
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  padding-top:5px !important;
  padding-bottom:5px !important;
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* Make header-content take the full height of the header cell */
  align-items: center;
  text-align: center;
}

.plan-button {
  margin-top: auto;
  /* Ensures the button sticks to the bottom */
  width: 100px;
  /* Optional: set a consistent width for the button */
  max-width: 100%;
}

.information_description {
  min-width: 150px;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 3s ease;
  /* Adjust speed and easing here */
}

.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
}

.scroll-right-enter-active {
  transition: all 0.6s ease-out;
}

.scroll-right-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.scroll-right-enter-from,
.scroll-right-leave-to {
  transform: translateX(40px);
  opacity: 0;
}
.animated-component.fade-enter-from,
.animated-component.zoom-enter-from {
    transition: none;
}

/* Fade animation */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 300ms ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* Zoom animation */
.zoom-enter-active,
.zoom-leave-active {
    transition: transform 300ms ease;
}

.zoom-enter-from,
.zoom-leave-to {
    transform: scale(0.9);
}
</style>