<template>


  <v-container fluid class="pa-0">
    <v-row justify="center" class=" pa-3" no-gutters>
      <v-col cols="12" md="6" sm="12">

        <transition name="scroll-right" appear>
          <h1 class="text-primary">แผนประกันสุขภาพ AIA H&S EXTRA แบบมีวงเงินผู้ป่วยนอก OPD</h1>
        </transition>
        <transition name="scroll-right" appear>
          <h2 class="">ให้ความคุ้มครองทั้งค่ารักษาพยาบาลทั้งผู้ป่วยใน (IPD) และผู้ป่วยนอก (OPD) ทำให้สะดวกเมื่อต้องไปโรงพยาบาล อีกทั้งผลประโยชน์เงินคืนพิเศษ กรณีที่ปีไหนไม่ได้เคลม</h2>
        </transition>

        <v-img src="aia-hs-extra-page/hs-extra-table-1.jpg"/>
        <v-img src="aia-hs-extra-page/hs-extra-table-2.jpg"/>
      
      
       <h2 class="text-primary">จุดเด่นของแผนประกันสุขภาพ AIA H&S EXTRA</h2>
       
       <v-list tag='ul'>
        <v-list-item tag='li'><b>- ค่าห้อง ค่าอาหาร บริการโรงพยาบาล</b> สูงสุดวันละ 6,500 บาท</v-list-item>
        <v-list-item tag='li'><b>- ค่าแพทย์</b> สูงสุดวันละ 1,200 บาท</v-list-item>
          <v-list-item tag='li'><b>- ค่าผ่าตัดและหัตถการ</b> สูงสุดครั้งละ 120,000 บาท</v-list-item>
            <v-list-item tag='li'><b>- ค่ารักษาพยาบาลอื่นๆ</b>  (เช่น ค่ายา น้ำเกลือ ค่าเวชภัณฑ์ ค่าตรวจทางห้องปฏิบัติการ ค่าพยาบาลประจำวัน) สูงสุดครั้งละ 40,000 บาท ต่อการรักษาครั้งใดครั้งหนึ่ง</v-list-item>
              <v-list-item tag='li'><b>- ค่ารักษาพยาบาลผู้ป่วยนอก (OPD)</b> สูงสุดปีละ 9,000 บาท</v-list-item>
                <v-list-item tag='li'><b>- ผลประโยชน์เงินคืนพิเศษ</b> สูงสุดปีละ 4,500 บาท</v-list-item>
                  <v-list-item tag='li'><b>- ให้ความคุ้มครองได้ตั้งแต่อายุ 15 วัน – 85 ปี</b></v-list-item>
        </v-list>
        <v-img src="aia-hs-extra-page/hs-extra-table-3.jpg"/>
         <v-row class="mb-5">
      <v-col justify="center" class="text-center" cols="12"><v-btn color="primary"  @click="navigateToCalPremium">
          คำนวณเบี้ยประกัน
          คลิกเลย
        </v-btn></v-col>
    </v-row>




<h3 class="text-primary">ตัวอย่างการวางแผนด้วย AIA H&S EXTRA</h3>
<p>ลูกค้าเพศหญิงอายุ 25 ปี ทำแผนวงเงินค่ารักษาพยาบาลแผนค่าห้องวันละ 6,500 บาท มีค่ารักษาพยาบาลแบบ OPD ปีละ 9,000 บาท ถ้าปีไหนไม่ได้เคลม (ทั้งผู้ป่วยในและผู้ป่วยนอก) มีเงินคืนพิเศษปีละ 4,500 บาท + ประกันชีวิตหลัก (20 PLNP) ความคุ้มครองชีวิต 100,000 บาท
</p><br><p>
ค่าเบี้ยรวม  24,050 + 1,631 = <b class="text-primary"> 25,681 </b>บาท

</p>
 <v-img src="aia-hs-extra-page/hs-extra-exam-1.jpg"/>

 <h2 class="text-primary">คำถามที่พบบ่อยเกี่ยวกับแผน AIA H&S EXTRA</h2>
 <h3 class="">นิยาม ผู้ป่วยใน (IPD) และ ผู้ป่วยนอก (OPD) ของ AIA</h3>
 <v-list tag='ul'>
        <v-list-item tag='li'><b>- ผู้ป่วยใน (IPD)</b> หมายถึง ผู้ที่จำเป็นต้องเข้ารับการรักษาในโรงพยาบาลติดต่อกันไม่น้อยกว่า 6 ชั่วโมง ซึ่งต้องลงทะเบียนเป็นผู้ป่วยในโดยได้รับการวินิจฉัย และคำแนะนำจากแพทย์ตามข้อบ่งชี้ ซึ่งเป็นมาตรฐานทางการแพทย์ และในระยะเวลาที่เหมาะสมสำหรับการรักษาการบาดเจ็บหรือการเจ็บป่วยนั้นๆ และให้รวมถึงกรณีรับตัวให้เป็นผุ้ป่วยในแล้วต่อมาเสียชีวิตก่อนครบ 6 ชั่วโมง</v-list-item>
        <v-list-item tag='li'><b>-  ผู้ป่วยนอก (OPD)</b> หมายถึง ผู้ที่รับบริการอันเนื่องจากการรักษาพยาบาลในแผนกผู้ป่วยนอกของโรงพยาบาล หรือในห้องรักษาฉุกเฉินของโรงพยาบาล ซึ่งไม่มีความจำเป็นตามข้อวินิจฉัยและข้อบ่งชี้ซึ่งเป็นมาตรฐานทางการแพทย์ในการเข้ารักษาเป็นผู้ป่วยใน</v-list-item>
        </v-list>

      </v-col>
    </v-row>

   

   
  </v-container>

  <v-snackbar v-model="snackbar" :timeout="3000" top color="primary">
    {{ snackbarMessage }}
    <v-btn color="secondary" text @click="snackbar = false">ปิด</v-btn>
  </v-snackbar>
</template>

<script>
import { ref, onMounted,onUnmounted, watch, nextTick } from 'vue';
import axios from '../plugins/axios'; // Import the custom axios instance
import logoPath from '../assets/logo.png';


export default {
  mounted() {
    document.title = "AIA Prime One"
    const metaTags = [
      { property: "og:title", content: "AIA Prime One" },
      { property: "og:description", content: "AIAPrimeONE เป็นตัวแทนประกันที่พร้อมจะดูแลและปกป้องคุณและครอบครัวในทุกสถานการณ์" },
      { property: "og:url", content: "https://aiaprimeone.com" },
      { property: "og:type", content: "website" }
    ];

    metaTags.forEach(tag => {
      const metaElement = document.createElement("meta");
      metaElement.setAttribute("property", tag.property);
      metaElement.setAttribute("content", tag.content);
      document.head.appendChild(metaElement);
    });
  },
  setup() {

    const drawer = ref(false);
    const snackbar = ref(false); // Controls the visibility of the snackbar
    const snackbarMessage = ref(''); // Holds the message to display in the snackbar
    const target = ref(null);
    const appBarHeight = 64;
    


  
    onMounted(() => {
    });
    onUnmounted(() => {
    });


    return {
      drawer,
      snackbar,
      snackbarMessage,
      appBarHeight
    };
  },
  computed: {
  },
  data() {
    return {
      logo: logoPath,
      // banners1: [
      //   {
      //     src: '/banner-master.jpg',
      //     // title: 'Welcome to our site',
      //     // description: 'This is the first banner description',
      //   },
      //   {
      //     src: '/banner-agent.jpg',
      //     // title: 'Discover our products',
      //     // description: 'This is the second banner description',
      //   },

      // ]

    };

  },

  methods: {
    navigateToCalPremium() {
      this.$router.push('/cal-premium'); // Ensure your router is set up for this path
    },
  }
};
</script>


<style scoped>
.v-card {
  margin-bottom: 1rem;
}

.header-cell {
  height: 200px;
  /* Set a fixed height for each header cell */
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  padding-top:5px !important;
  padding-bottom:5px !important;
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* Make header-content take the full height of the header cell */
  align-items: center;
  text-align: center;
}

.plan-button {
  margin-top: auto;
  /* Ensures the button sticks to the bottom */
  width: 100px;
  /* Optional: set a consistent width for the button */
  max-width: 100%;
}

.information_description {
  min-width: 150px;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 3s ease;
  /* Adjust speed and easing here */
}

.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
}

.scroll-right-enter-active {
  transition: all 0.6s ease-out;
}

.scroll-right-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.scroll-right-enter-from,
.scroll-right-leave-to {
  transform: translateX(40px);
  opacity: 0;
}
.animated-component.fade-enter-from,
.animated-component.zoom-enter-from {
    transition: none;
}

/* Fade animation */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 300ms ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* Zoom animation */
.zoom-enter-active,
.zoom-leave-active {
    transition: transform 300ms ease;
}

.zoom-enter-from,
.zoom-leave-to {
    transform: scale(0.9);
}
</style>