<template>
    <v-container>
        <v-row><v-col><v-img :src="bannerApplicationForm" cover
            max-height="250"></v-img></v-col></v-row>
        <v-row><v-col>
                <h1 class="">กรอกข้อมูลของท่าน สมัครประกันบริษัท AIA ส่งให้ตัวแทน</h1>
            </v-col></v-row>
        <v-form ref="form" v-model="valid" lazy-validation @submit.prevent="submitForm">
            <v-row>

                <v-col><h2 class="text-primary">ข้อมูลผู้เอาประกัน</h2></v-col>

                <!-- ID Card File -->
                <v-col cols="12" class="pb-0 pt-0">
                    <v-file-input v-model="idCardFile" density="compact" label="ถ่ายบัตรประชาชน (ถ้าไม่สะดวกพิมพ์ข้อมูลบัตร)"
                        accept=".jpg,.jpeg,.png,.pdf" @change="uploadFile"></v-file-input>
                </v-col>

                <!-- Full Name (Thai) -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="fullNameThai" density="compact" label="คำนำหน้า ชื่อ-นามสกุล ภาษาไทย"
                        :rules="[v => !!v || 'Required']" required></v-text-field>
                </v-col>

                <!-- Full Name (English) -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="fullNameEnglish" density="compact" label="คำนำหน้า ชื่อ-นามสกุล ภาษาอังกฤษ"
                        :rules="[v => !!v || 'Required']" required></v-text-field>
                </v-col>

                <!-- ID Card -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="idCard" density="compact" label="เลขที่บัตรประชาชน / Passport ID"
                        :rules="[v => !!v || 'Required']" required></v-text-field>
                </v-col>

                <!-- Birth Date -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="birthDate" density="compact" label="ว/ด/ป เกิด" type="date"
                        :formatter="format"></v-text-field>
                </v-col>

                <!-- Expiry Date -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="expiryDate" density="compact" label="ว/ด/ป บัตรหมดอายุ"
                        type="date" :formatter="format"></v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="idCardSource" density="compact" label="ออกโดย" type="text"></v-text-field>
                </v-col>

                <!-- Address -->
                <v-col cols="12" class="pb-0 pt-0">
                    <v-textarea v-model="address" density="compact" label="ที่อยู่ตามบัตรประชาชน"></v-textarea>
                </v-col>

                <!-- Contact Address -->
                <v-col cols="12" class="pb-0 pt-0">
                    <v-textarea v-model="contactAddress" density="compact" label="ที่อยู่ตามจัดส่งเอกสาร"></v-textarea>
                </v-col>
                <!-- Work Address -->
                <v-col cols="12" class="pb-0 pt-0">
                    <v-textarea v-if="!underAge" density="compact"  v-model="workAddress" label="ที่อยู่ที่ทำงาน"></v-textarea>
                </v-col>

                <v-col cols="12" md="12" class="pb-0 pt-0">
                    <v-text-field v-model="formerName" density="compact" label="คำนำหน้า ชื่อ-นามสกุล เดิม"></v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <span class="text-weight-bold mb-2">สถานภาพ</span>
                    <v-radio-group v-model="maritalStatus" density="compact" color="primary" inline>
                        <v-radio label="โสด" value="โสด"></v-radio>
                        <v-radio label="หย่าร้าง" value="หย่าร้าง"></v-radio>
                        <v-radio label="หม้าย" value="หม้าย"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <span class="text-weight-bold mb-2">เพศ</span>
                    <v-radio-group v-model="gender" density="compact" color="primary" inline >
                        <v-radio label="ชาย" value="ชาย"></v-radio>
                        <v-radio label="หญิง" value="หญิง"></v-radio>
                    </v-radio-group>
                </v-col>

                <!-- Height -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="height" density="compact" label="ส่วนสูง" type="number" :rules="[v => !!v || 'Required']"
                        required></v-text-field>
                </v-col>

                <!-- Weight -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="weight" density="compact" label="น้ำหนัก" type="number" :rules="[v => !!v || 'Required']"
                        required></v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="nationality" density="compact" label="สัญชาติ" type="text"></v-text-field>
                </v-col>

               


                <!-- Occupation -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="occupation" density="compact" label="อาชีพ"></v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="typeOfBusiness" density="compact" label="ประเภทธุรกิจ" type="text"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="natureOfWork" density="compact" label="ลักษณะงานที่ทำ" type="text"></v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="position" label="ตำแหน่ง" density="compact" type="text"></v-text-field>
                </v-col>

                <!-- Monthly Income -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="monthlyIncome" density="compact" label="รายได้/เดือน" type="number"></v-text-field>
                </v-col>


                <!-- Contact Number -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="contactNumber" density="compact" label="เบอร์โทร" type="tel"></v-text-field>
                </v-col>

                <!-- Email -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="email" label="Email" density="compact" type="email"></v-text-field>
                </v-col>

                <v-col cols="12" class="pb-0 pt-0">
                    <v-text-field v-model="hospitalName" density="compact" label="ชื่อโรงพยาบาลที่ใช้บริการ"></v-text-field>
                </v-col>

                <!-- Chronic Disease -->
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <span class="text-weight-bold mb-2">โรคประจำตัว</span>
                    <v-radio-group v-model="chronicDisease" color="primary" density="compact" inline>
                        <v-radio label="ไม่มี" value="ไม่มี"></v-radio>
                        <v-radio label="มี" value="มี"></v-radio>
                    </v-radio-group>
                </v-col>

                <!-- Disease Details -->
                <v-col cols="12" md="6" v-if="chronicDisease === 'มี'" class="pb-0 pt-0">
                    <v-text-field v-model="diseaseDetails" density="compact" label="กรณีมีโรคประจำตัว โปรดแจ้งชื่อโรค"></v-text-field>
                </v-col>

                <v-col cols="12" class="pb-0 pt-0">
                    <v-text-field density="compact" v-model="emeregencyContact"
                        label="ผู้ที่สามารถติดต่อได้กรณีติดต่อผู้ทำประกันไม่ได้"></v-text-field>
                </v-col>
                <v-col><h2 class="text-primary">กรณีผู้เอาประกันอายุต่ำกว่า 20 ปี</h2></v-col>
                <v-col cols="12" class="pb-0 pt-0">
                    <v-checkbox v-model="underAge" density="compact" label="ผู้เอาประกันอายุตำกว่า 20 ปี"></v-checkbox></v-col>
                <v-col v-if="underAge" cols="12" class="pb-0 pt-0">
                    <v-file-input v-model="birthCertFile" density="compact" label="ใบสูติบัตรผู้เอาประกัน กรณีไม่มีบัตรประชาชน"
                        accept=".jpg,.jpeg,.png,.pdf"></v-file-input>
                </v-col>


                <!-- ID Card File -->
                <v-col v-if="underAge" cols="12" class="pb-0 pt-0">
                    <v-file-input v-model="relationIdCardFile" density="compact" label="ถ่ายบัตรประชาชน (ถ้าไม่สะดวกพิมพ์ข้อมูลบัตร)"
                        accept=".jpg,.jpeg,.png,.pdf" @change="uploadRelationFile"></v-file-input>
                </v-col>

                <!-- Full Name (Thai) -->
                <v-col v-if="underAge" cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="relationName" density="compact" label="คำนำหน้า ชื่อ-นามสกุล ภาษาไทย"
                        :rules="[v => !!v || 'Required']" required></v-text-field>
                </v-col>

                <v-col v-if="underAge" cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="relationExpiryDate" density="compact" label="ว/ด/ป บัตรหมดอายุ" type="date"
                        :formatter="format"></v-text-field>
                </v-col>

                <!-- ID Card -->
                <v-col v-if="underAge" cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="relationIdCard" density="compact" label="เลขที่บัตรประชาชน / Passport ID"
                        :rules="[v => !!v || 'Required']" required></v-text-field>
                </v-col>

                <!-- ID Card -->
                <v-col v-if="underAge" cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="relationMobileNo" density="compact" label="เบอร์โทร"></v-text-field>
                </v-col>

                <v-col v-if="underAge" cols="12" class="pb-0 pt-0">
                    <span class="text-weight-bold mb-2">เพศ</span>
                    <v-radio-group v-model="relationGender" density="compact" color="primary" inline>
                        <v-radio label="ชาย" value="ชาย"></v-radio>
                        <v-radio label="หญิง" value="หญิง"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col v-if="underAge" cols="12" class="pb-0 pt-0">
                    <v-checkbox v-model="relationAccept" density="compact" label="เจ้าของกรมธรรม์ / ผู้ให้ความยินยอม" value="relationAccept"
                        ></v-checkbox>
                </v-col>



                



               
                <v-col><h2 class="text-primary">ข้อมูลผู้รับผลประโยชน์</h2></v-col>

                <v-col cols="12" v-for="(beneficiary, index) in beneficiaries" :key="index" class="pb-0 pt-0">
                    <v-row>
                        <v-col><h3>ผู้รับผลประโยชน์รายที่ {{index+1}}</h3></v-col>
                        <!-- Beneficiary ID Card Upload -->
                        <v-col cols="12" class="pb-0 pt-0">
                            <v-file-input density="compact" v-model="beneficiaries[index].idCardFile"
                                label="ถ่ายบัตรประชาชนผู้รับผลประโยชน์ (ถ้าไม่สะดวกพิมพ์ข้อมูลบัตร)" accept=".jpg,.jpeg,.png,.pdf"
                                @change="uploadBeneficiaryIDCard(index)"></v-file-input>
                        </v-col>
                        <!-- Beneficiary Full Name -->
                        <v-col cols="12" md="6" class="pb-0 pt-0">
                            <v-text-field density="compact" v-model="beneficiaries[index].fullname" label="ชื่อ-นามสกุล"
                                ></v-text-field>
                        </v-col>

                        <!-- Beneficiary Age -->
                        <v-col cols="12" md="6" class="pb-0 pt-0">
                            <v-text-field density="compact" v-model="beneficiaries[index].age" label="อายุ" type="number"
                               ></v-text-field>
                        </v-col>

                        
                       

                        <!-- Beneficiary ID Card No -->
                        <v-col cols="12" md="6" class="pb-0 pt-0">
                            <v-text-field density="compact" v-model="beneficiaries[index].idCardNo" label="เลขที่บัตรประชาชน / Passport ID"
                                ></v-text-field>
                        </v-col>

                        <!-- Beneficiary Relation -->
                        <v-col cols="12" md="6" class="pb-0 pt-0">
                            <v-text-field density="compact" v-model="beneficiaries[index].relation" label="ความสัมพันธ์"
                                ></v-text-field>
                        </v-col>
                        <!-- Beneficiary Gender -->
                        <v-col cols="12" md="12" class="pb-0 pt-0">
                            <v-radio-group density="compact" v-model="beneficiaries[index].gender" label="เพศ" color="primary" inline >
                                <v-radio label="ชาย" value="ชาย"></v-radio>
                                <v-radio label="หญิง" value="หญิง"></v-radio>
                            </v-radio-group>
                        </v-col>

                        <!-- Beneficiary Address -->
                        <v-col cols="12" class="pb-0 pt-0">
                            <v-textarea density="compact" v-model="beneficiaries[index].address" label="ที่อยู่"></v-textarea>
                        </v-col>


                        <!-- Remove Button -->
                        <v-col cols="12" class="text-right">
                            <v-btn color="primary" density="compact" text @click="removeBeneficiary(index)">ลบผู้รับผลโยชน์รายที่ {{ index+1 }}</v-btn>
                        </v-col>
                    </v-row>
                    
                </v-col>
            
                    <v-col cols="12" class="text-right">
                        <v-btn color="success" density="compact" text @click="addBeneficiary">เพิ่มผู้รับผลโยชน์</v-btn>
                    </v-col>
          



                <!-- <v-col cols="12" class="pb-0 pt-0">
                    <v-text-field v-model="beneficiary" label="ชื่อผู้รับผลประโยชน์ อายุ ที่อยู่"></v-text-field>
                </v-col> -->
                <!-- <v-col cols="8" class="pb-0 pt-0">
                    <v-text-field v-model="fatherName" label="ชื่อ-สกุล บิดา ภาษาไทย"></v-text-field>
                </v-col>
                <v-col cols="4" class="pb-0 pt-0">
                    <v-text-field v-model="fatherAge" label="บิดาอายุ"></v-text-field>
                </v-col>
                <v-col cols="8" class="pb-0 pt-0">
                    <v-text-field v-model="motherName" label="ชื่อ-สกุล มารดา ภาษาไทย"></v-text-field>
                </v-col>
                <v-col cols="4" class="pb-0 pt-0">
                    <v-text-field v-model="motherAge" label="มารดาอายุ"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0 pt-0">
                    <v-text-field v-model="spouseName" label="ชื่อ-สกุล คู่สมรส ภาษาไทย"></v-text-field>
                </v-col>
                <v-col cols="6" md="3" class="pb-0 pt-0">
                    <v-text-field v-model="spouseAge" label="อายุ คู่สมรส" type="number"></v-text-field>
                </v-col>
                <v-col cols="6" md="3" class="pb-0 pt-0">
                    <v-text-field v-model="spouseBirthDate" label="ว/ด/ป เกิดคู่สมรส" type="date"
                        :formatter="format"></v-text-field>
                </v-col> -->
                <v-col><h2 class="text-primary">ข้อมูลการชำระเงิน</h2></v-col>
                <v-col cols="12" class="pb-0 pt-0">
                    <span class="text-weight-bold mb-2">การชำระเงิน</span>
                    <v-radio-group v-model="billingCycle" density="compact" color="primary">

                        <v-radio label="1 เดือน" value="1 เดือน"></v-radio>
                        <v-radio label="3 เดือน" value="3 เดือน"></v-radio>
                        <v-radio label="6 เดือน" value="6 เดือน"></v-radio>
                        <v-radio label="1 ปี" value="1 ปี"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="12" class="pb-0 pt-0">
                    <span class="text-weight-bold mb-2">ช่องทางการชำระ</span>
                    <v-radio-group v-model="paymentMethod" density="compact" color="primary" row>
                        <v-radio label="บัตรเครดิต" value="CREDIT_CARD"></v-radio>
                        <v-radio label="สแกน QR Code" value="QR_CODE"></v-radio>
                    </v-radio-group>
                </v-col>
                <v-col cols="12" md="6" v-if="paymentMethod === 'CREDIT_CARD'" class="pb-0 pt-0">
                    <v-text-field v-model="creditCardNo" density="compact" label="แจ้งเลขบัตร 16 หลัก"></v-text-field>
                </v-col>
                <v-col cols="12" md="6" v-if="paymentMethod === 'CREDIT_CARD'" class="pb-0 pt-0">
                    <v-text-field v-model="creditCardExpire" density="compact" label="วันหมดอายุบัตรเครดิต ด/ป"></v-text-field>
                </v-col>
                <v-col cols="12" class="pb-0 pt-0">
                    <v-textarea v-model="remark" density="compact" label="ข้อความถึงตัวแทน (ถ้ามี)"></v-textarea>
                </v-col>
                <v-checkbox v-model="tcAccept"
                    density="compact" value="tcAccept" :rules="[v => !!v || 'Required']" required>
                    <template v-slot:label>
                        <span>
                            ข้าพเจ้ายอมรับข้อกำหนดและเงื่อนไขทั้งหมดของการสมัครประกัน และยินยอมให้บริษัทเก็บรวบรวมและใช้ข้อมูลส่วนบุคคล (PDPA)
          <a href="javascript:void(0);" @click="openModal" style="color: blue; text-decoration: underline;">อ่านเพิ่มเติม</a>
        </span>
      </template></v-checkbox>



                <!-- Submit Button -->
                <v-col cols="12" class="pb-0 pt-0">
                    <v-btn :disabled="!valid" type="submit" color="primary"><v-progress-circular v-if="loading"
                            indeterminate size="20" class="mr-2"></v-progress-circular>ส่งข้อมูล</v-btn>
                </v-col>
            </v-row>
        </v-form>
        <!-- Modal -->
    <v-dialog v-model="modalVisible" max-width="500px">
        <v-btn
          icon
          class="close-btn"
        @click="modalVisible = false"
          style="position: absolute; top: 10px; right: 10px; z-index: 100"
        >
          <v-icon color="black">mdi-close</v-icon>
        </v-btn>
      <v-card>
        <v-card-title>ข้อกำหนดเงื่อนไข <br>และการใช้ข้อมูลส่วนบุคคล</v-card-title>
        <v-card-text class="modal-font" >
            <h3>ข้อกำหนดและเงื่อนไข (Terms &amp; Conditions)</h3>
<ol class="terms-list" >
    <li><strong>การสมัครประกันชีวิต</strong><br>ผู้สมัครต้องให้ข้อมูลที่ถูกต้องและครบถ้วนในการสมัครประกันชีวิต รวมถึงข้อมูลส่วนบุคคล ข้อมูลเกี่ยวกับประกันชีวิต และข้อมูลทางการเงินอื่นๆ ที่จำเป็นในการดำเนินการ</li>
    <li><strong>การยอมรับข้อกำหนด</strong><br>เมื่อผู้สมัครได้กรอกข้อมูลในแบบฟอร์มสมัครประกันชีวิตและยอมรับข้อกำหนดและเงื่อนไขนี้ ผู้สมัครถือว่าท่านยอมรับข้อกำหนดและเงื่อนไขทั้งหมดตามที่ได้ระบุไว้ในเอกสารนี้</li>
    <li><strong>การตรวจสอบข้อมูล KYC (Know Your Customer)</strong><br>ผู้สมัครยินยอมให้บริษัททำการตรวจสอบข้อมูลส่วนบุคคลเพื่อยืนยันตัวตนตามข้อกำหนดของกฎหมาย และให้ข้อมูลที่เกี่ยวข้องทั้งหมดแก่บริษัทประกันชีวิต เพื่อการตรวจสอบและอนุมัติการสมัครประกันชีวิต</li>
    <li><strong>การใช้ข้อมูล</strong><br>ข้อมูลที่ผู้สมัครให้มาอาจถูกนำไปใช้เพื่อวัตถุประสงค์ต่างๆ รวมถึงการยืนยันตัวตน การประเมินความเสี่ยง และการให้บริการประกันชีวิตแก่ผู้สมัคร</li>
    <li><strong>การยกเลิกการสมัคร</strong><br>ผู้สมัครสามารถยกเลิกการสมัครประกันชีวิตได้ทุกเวลา โดยการแจ้งให้บริษัททราบตามวิธีการที่บริษัทกำหนด</li>
    <li><strong>ข้อกำหนดอื่นๆ</strong><br>บริษัทมีสิทธิในการเปลี่ยนแปลงข้อกำหนดและเงื่อนไขนี้โดยการแจ้งให้ผู้สมัครทราบล่วงหน้า</li>
</ol>
<br>
<h3>การยินยอมตามพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล (PDPA)</h3>
<ol class="terms-list">
    <li><strong>การเก็บรวบรวมและการใช้ข้อมูลส่วนบุคคล</strong><br>ผู้สมัครยินยอมให้บริษัทเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคลที่ได้ให้มาในแบบฟอร์มสมัครประกันชีวิต เพื่อการดำเนินการตามวัตถุประสงค์ของการสมัครประกันชีวิตนี้ รวมถึงการประมวลผลตามกฎหมายที่เกี่ยวข้อง</li>
    <li><strong>การเปิดเผยข้อมูลส่วนบุคคลแก่บุคคลที่สาม</strong><br>ข้อมูลส่วนบุคคลที่ผู้สมัครให้มาจะถูกเปิดเผยให้แก่บุคคลที่สามตามที่กฎหมายอนุญาตหรือเพื่อวัตถุประสงค์ที่เกี่ยวข้องกับการให้บริการประกันชีวิต</li>
    <li><strong>การจัดการข้อมูลส่วนบุคคล</strong><br>ผู้สมัครมีสิทธิในการเข้าถึงและขอแก้ไขข้อมูลส่วนบุคคลที่เกี่ยวข้องกับการสมัครประกันชีวิต รวมถึงการขอให้ลบข้อมูลดังกล่าวหากไม่จำเป็นต้องใช้ในการดำเนินการตามวัตถุประสงค์ที่ได้ตกลง</li>
    <li><strong>การยินยอม</strong><br>ผู้สมัครยินยอมและเข้าใจว่าข้อมูลส่วนบุคคลที่ให้มาจะถูกเก็บรวบรวมและใช้ตามที่ระบุในนโยบายคุ้มครองข้อมูลส่วนบุคคลของบริษัท</li>
</ol>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="modalVisible = false">ปิด</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-container>
    <v-snackbar v-model="snackbar" :timeout="3000" top color="primary">
        {{ snackbarMessage }}
        <v-btn color="secondary" text @click="snackbar = false">ปิด</v-btn>
    </v-snackbar>
</template>

<script>
import { ref, computed } from "vue";
import axios from '../plugins/axios'; // Import the custom axios instance

export default {
    setup() {
        // Reactive form fields
        const fullNameThai = ref("");
        const fullNameEnglish = ref("");
        const height = ref("");
        const weight = ref("");
        const idCard = ref("");
        const birthDate = ref("");
        const expiryDate = ref("");
        const address = ref("");
        const contactAddress = ref("");
        const idCardFile = ref(null);
        const relationIdCardFile = ref(null);
        const birthCertFile = ref(null);
        const occupation = ref("");
        const monthlyIncome = ref("");
        const workAddress = ref("");
        const contactNumber = ref("");
        const email = ref("");
        const chronicDisease = ref("ไม่มี");
        const diseaseDetails = ref("");
        const emeregencyContact = ref("");
        const beneficiary = ref("");
        const fatherName = ref("");
        const fatherAge = ref("");
        const motherName = ref("");
        const motherAge = ref("");
        const spouseName = ref("");
        const spouseAge = ref("");
        const spouseBirthDate = ref("");
        const hospitalName = ref("");
        const billingCycle = ref("");
        const paymentMethod = ref("");
        const creditCardNo = ref("");
        const creditCardExpire = ref("");
        const gender = ref("");
        const maritalStatus = ref("");
        const formerName = ref("");
        const annualIncome = ref("");
        const relationExpiryDate = ref("");
        const relationGender = ref("");
        const relationIdCard = ref("");
        const relationName = ref("");
        const relationAccept = ref("");
        const relationMobileNo = ref("");
        const nationality = ref("");
        const natureOfWork = ref("");
        const position = ref("");
        const typeOfBusiness = ref("");
        const idCardSource = ref("");
        const remark = ref("");
        const tcAccept = ref("");
        const snackbar = ref(false); // Controls the visibility of the snackbar
        const snackbarMessage = ref(''); // Holds the message to display in the snackbar
        const loading = ref(false); // Loading state
        const underAge = ref(false);
        const beneficiaries = ref([
            { fullname: "", age: null, gender: "", idCardNo: "", relation: "", address: "", idCardFile: null }
        ]);


        // Add a new beneficiary
        const addBeneficiary = () => {
            beneficiaries.value.push({ fullname: "", age: null, gender: "", idCardNo: "", relation: "", address: "", idCardFile: null });
        };
        const removeBeneficiary = (index) => {
            beneficiaries.value.splice(index, 1);
        };

        // Construct formData dynamically


        // Form validation state
        const valid = ref(false);

        const modalVisible = ref(false);


const openModal = (event) => {
    console.log("openmodal")
  event.preventDefault(); // Prevent page refresh
  modalVisible.value = true; // Show the modal
};


        const uploadFile = async () => {
            if (!idCardFile.value) {
                snackbarMessage.value = "กรุณาแนบรูป";
                snackbar.value = true;
                return;
            }

            const formData = new FormData();
            formData.append("IDCardFile", idCardFile.value);

            try {
                const response = await axios.post("/ocr", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                const data = response.data;

                // Prefill form fields with extracted data
                idCard.value = data.idCard || "";
                birthDate.value = data.birthDate || "";
                fullNameThai.value = data.fullNameThai || "";
                fullNameEnglish.value = data.fullNameEnglish || "";
                address.value = data.address || "";
                contactAddress.value = data.address || "";
                expiryDate.value = data.expiryDate || "";

                //alert("Form prefilled with extracted data!");
            } catch (error) {
                console.error("Error uploading file:", error);
                snackbarMessage.value = "เกิดข้อผิดพลาด";
                snackbar.value = true;
            }
        };

        const uploadRelationFile = async () => {
            if (!relationIdCardFile.value) {
                snackbarMessage.value = "กรุณาแนบรูป";
                snackbar.value = true;
                return;
            }

            const formData = new FormData();
            formData.append("IDCardFile", relationIdCardFile.value);

            try {
                const response = await axios.post("/ocr", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                const data = response.data;

                // Prefill form fields with extracted data
                relationIdCard.value = data.idCard || "";
                //birthDate.value = data.birthDate || "";
                relationName.value = data.fullNameThai || "";
                //fullNameEnglish.value = data.fullNameEnglish || "";
                //address.value = data.address || "";
                //contactAddress.value = data.address || "";
                relationExpiryDate.value = data.expiryDate || "";

                //alert("Form prefilled with extracted data!");
            } catch (error) {
                console.error("Error uploading file:", error);
                snackbarMessage.value = "เกิดข้อผิดพลาด";
                snackbar.value = true;
            }
        };

        const uploadBeneficiaryIDCard = async (index) => {
            const file = beneficiaries.value[index].idCardFile;

            if (!file) {
                snackbarMessage.value = "กรุณาแนบรูปบัตรประชาชนผู้รับผลประโยชน์";
                snackbar.value = true;
                return;
            }

            const formData = new FormData();
            formData.append("IDCardFile", file);

            try {
                const response = await axios.post("/ocr", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                const data = response.data;

                // Prefill beneficiary fields with extracted data

                beneficiaries.value[index].idCardNo = data.idCard || "";
                beneficiaries.value[index].fullname = data.fullNameThai || "";
                beneficiaries.value[index].address = data.address || "";

                // snackbarMessage.value = "OCR สำเร็จสำหรับผู้รับผลประโยชน์";
                // snackbar.value = true;
            } catch (error) {
                console.error("Error uploading beneficiary ID card:", error);
                snackbarMessage.value = "เกิดข้อผิดพลาดในการ OCR สำหรับผู้รับผลประโยชน์";
                snackbar.value = true;
            }
        };

        // Form submission



        const submitForm = async () => {
            if (valid.value) {

                loading.value = true; // Set loading to true
                const formData = new FormData();

                // Append text fields
                formData.append("FullNameThai", fullNameThai.value);
                formData.append("FullNameEnglish", fullNameEnglish.value);
                formData.append("Height", height.value);
                formData.append("Weight", weight.value);
                formData.append("IDCard", idCard.value);
                formData.append("BirthDate", birthDate.value);
                formData.append("ExpiryDate", expiryDate.value);
                formData.append("Address", address.value);
                formData.append("ContactAddress", contactAddress.value);
                formData.append("Occupation", occupation.value);
                formData.append("MonthlyIncome", monthlyIncome.value);
                formData.append("WorkAddress", workAddress.value);
                formData.append("ContactNumber", contactNumber.value);
                formData.append("Email", email.value);
                formData.append("ChronicDisease", chronicDisease.value);
                formData.append("DiseaseDetails", diseaseDetails.value);
                formData.append("Beneficiary", beneficiary.value);
                formData.append("FatherName", fatherName.value);
                formData.append("FatherAge", fatherAge.value);
                formData.append("MotherName", motherName.value);
                formData.append("MotherAge", motherAge.value);
                formData.append("SpouseName", spouseName.value);
                formData.append("SpouseAge", spouseAge.value);
                formData.append("SpouseBirthDate", spouseBirthDate.value);
                formData.append("HospitalName", hospitalName.value);
                formData.append("BillingCycle", billingCycle.value);
                formData.append("PaymentMethod", paymentMethod.value);
                formData.append("CreditCardNo", creditCardNo.value);
                formData.append("CreditCardExpire", creditCardExpire.value);
                formData.append("Remark", remark.value);
                formData.append("TcAccpet", tcAccept.value);
                formData.append("Gender", gender.value);
                formData.append("RelationName", relationName.value);
                formData.append("RelationMobileNo", relationMobileNo.value);
                formData.append("RelationAccept", relationAccept.value);
                formData.append("RelationExpiryDate", relationExpiryDate.value);
                formData.append("RelationIdCardFile", relationIdCardFile.value);
                formData.append("RelationGender", relationGender.value);
                formData.append("RelationAccept", relationAccept.value);
                formData.append("MaritalStatus", maritalStatus.value);
                formData.append("FormerName", formerName.value);
                formData.append("Nationality", nationality.value);
                formData.append("IdCardSource", idCardSource.value);
                formData.append("RelationIdCard", relationIdCard.value);
                formData.append("Position", position.value);
                formData.append("NatureOfWork", natureOfWork.value);
                formData.append("TypeOfBusiness", typeOfBusiness.value);
                //formData.append("Beneficiaries", JSON.stringify(beneficiaries.value));
                formData.append("AnnualIncome", annualIncome.value);
// Serialize the beneficiaries to JSON
                const serializedBeneficiaries = beneficiaries.value.map(beneficiary => ({
                            fullname: beneficiary.fullname,
                            age: beneficiary.age ? parseInt(beneficiary.age, 10) : null, 
                            gender: beneficiary.gender,
                            idCardNo: beneficiary.idCardNo,
                            relation: beneficiary.relation,
                            address: beneficiary.address
                        }));

                formData.append("Beneficiaries", JSON.stringify(serializedBeneficiaries));


                if (idCardFile.value) {
                    console.log("Selected file:", idCardFile.value); // Debugging purpose
                    formData.append("IDCardFile", idCardFile.value);
                } else {
                    console.error("No file selected!");
                }
                if (relationIdCardFile.value) {
                    console.log("Selected file:", relationIdCardFile.value); // Debugging purpose
                    formData.append("RelationIDCardFile", relationIdCardFile.value);
                } else {
                    console.error("No file selected!");
                }

                
                if (birthCertFile.value) {
                    console.log("Selected file:", birthCertFile.value); // Debugging purpose
                    formData.append("BirthCertFile", birthCertFile.value);
                } else {
                    console.error("No file selected!");
                }
                 // Attach beneficiary files
                beneficiaries.value.forEach((beneficiary, index) => {
                    if (beneficiary.idCardFile) {
                        formData.append(`BeneficiaryIDCardFile${index}`, beneficiary.idCardFile);
                    } else {
                        console.warn(`No ID card file for beneficiary at index ${index}`);
                    }
                });
                try {
                    const response = await axios.post("customer_information", formData, {
                        headers: { "Content-Type": "multipart/form-data" },
                    });
                    snackbarMessage.value = "ขอบคุณค่ะ เราจะติดต่อกลับไป";
                    snackbar.value = true;

                } catch (error) {
                    console.error("Error submitting form:", error);
                    snackbarMessage.value = "เกิดข้อผิดพลาด";
                    snackbar.value = true;
                } finally {
                    loading.value = false; // Set loading to false
                }
            } else {
                snackbarMessage.value = "กรุฯากรอกข้อมูลให้ครบถ้วน";
                snackbar.value = true;
            }
        };



        return {
            fullNameThai,
            fullNameEnglish,
            height,
            weight,
            idCard,
            birthDate,
            expiryDate,
            address,
            contactAddress,
            idCardFile,
            occupation,
            monthlyIncome,
            workAddress,
            contactNumber,
            email,
            chronicDisease,
            diseaseDetails,
            valid,
            submitForm,
            uploadFile,
            uploadRelationFile,
            emeregencyContact,
            beneficiary,
            fatherName,
            fatherAge,
            motherName,
            motherAge,
            spouseName,
            spouseAge,
            spouseBirthDate,
            hospitalName,
            billingCycle,
            paymentMethod,
            creditCardNo,
            creditCardExpire,
            remark,
            tcAccept,
            snackbarMessage,
            snackbar,
            bannerApplicationForm: '/application-form.jpg',
            underAge,
            gender,
            maritalStatus,
            idCardSource,
            formerName,
            relationExpiryDate,
            relationGender,
            relationIdCard,
            relationIdCardFile,
            relationMobileNo,
            relationAccept,
            relationName,
            nationality,
            natureOfWork,
            position,
            typeOfBusiness,
            annualIncome,
            loading,
            beneficiaries,
            addBeneficiary,
            removeBeneficiary,
            uploadBeneficiaryIDCard,
            birthCertFile,
            openModal,
            modalVisible,
        };
    },
};
</script>
<style scoped>
.modal-font{
    font-size: 0.875rem !important;
}
.terms-list {
  padding-left: 20px; /* Add padding to the left of the ordered list */
  list-style-position: inside; /* Makes the numbering appear inside the padding */
}

.terms-list li {
  margin-bottom: 10px; /* Add space between list items */
}
</style>