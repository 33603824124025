<template>
  <v-app>

    <v-app-bar color="primary">
      <!--v-img
        class="mx-2"
        :src="logo" 
        max-height="40"
        contain
      ></v-img-->
      <img src="/web-app-manifest-192x192.png" alt="Logo" height="40" class="ml-5" @click="$router.push({ path: '/' })">
      <span class="ml-3 font-weight-bold" @click="$router.push({ path: '/' })">AIA Prime One</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="drawer = !drawer">
        <v-icon>mdi-menu</v-icon>
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" app>
      <v-list >
        <v-list-item prepend-icon="mdi-home" title="หน้าแรก" @click="$router.push({ path: '/' })"></v-list-item>



        <v-list-group  v-model="open" value="Product">
          <template v-slot:activator="{ props }">
            <v-list-item v-bind="props" prepend-icon="mdi-account-circle" title="แผนประกัน">
            </v-list-item>
          </template>

          <v-list-item title="AIA Health Saver" @click="$router.push({ path: '/aia-health-saver' })">
          </v-list-item>

          <v-list-item title="AIA Health Happy" @click="$router.push({ path: '/aia-health-happy' })">
          </v-list-item>

          <v-list-item title="AIA HS Extra" @click="$router.push({ path: '/aia-hs-extra' })">
          </v-list-item>

          <v-list-item title="AIA Health Happy Kids" @click="$router.push({ path: '/aia-health-happy-kids' })">
          </v-list-item>
        </v-list-group>

        <v-list-item prepend-icon="mdi-calculator" title="คำนวณเบี้ยประกัน"
          @click="$router.push({ path: '/cal-premium' })"></v-list-item>
        <v-list-item prepend-icon="mdi-form-select" @click="$router.push({ path: '/form-information' })"
          title="กรอกข้อมูลสมัครประกัน"></v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main class="d-flex align-center justify-center" style="min-height: 300px;">

      <router-view></router-view>



    </v-main>
    <!-- <v-card class="ma-5 pa-5">
       
        <v-btn color="primary" class="" @click="navigateToPage">
          คำนวณเบี้ยประกัน
          คลิกเลย
        </v-btn>
      </v-card> -->


    <v-footer padless color="primary" class="white--text">
      <v-container class="pa-2">
        <v-row align="center" justify="center" dense>
          <!-- Mobile Number -->
          <v-col cols="12" md="4" class="d-flex align-center justify-center pa-1 text-caption">
            <v-icon size="18" class="mr-1">mdi-phone</v-icon>
            <span>
              Mobile: <a class="text-white" href="tel:0612364256">06-1236-4256</a>
            </span>
          </v-col>

          <!-- Facebook Link -->
          <v-col cols="12" md="4" class="d-flex align-center justify-center pa-1 text-caption">
            <v-icon size="18" class="mr-1">mdi-facebook</v-icon>
            <span>
              Facebook:
              <a href="https://www.facebook.com/aiaprimeone" target="_blank" class="text-white">
                AIA Prime One
              </a>
            </span>
          </v-col>

          <!-- Line ID -->
          <v-col cols="12" md="4" class="d-flex align-center justify-center pa-1 text-caption">
            <v-icon size="18" class="mr-1">mdi-chat</v-icon>
            <span>
              Line:
              <a href="https://liff.line.me/1645278921-KWRPP32q/?accountId=941jcqlm" target="_blank" class="text-white">
                AIA Prime One
              </a>
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col class="text-center text-caption pa-1">
            เว็บไซต์นี้ไม่ใช่เว็บไซต์ของบริษัท เอไอเอ จำกัด (เอไอเอ) หากต้องการติดต่อ เอไอเอ กรุณาคลิก
            <a href="https://www.aia.co.th" target="_blank" class="text-white">www.aia.co.th</a>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>


  </v-app>
</template>

<script>
import { ref, onMounted, onUnmounted, watch, nextTick } from 'vue';

export default {
  name: 'App',
  mounted() {
    document.title = "AIA Prime One"
    const metaTags = [
      { property: "og:title", content: "AIA Prime One" },
      { property: "og:description", content: "AIAPrimeONE เป็นตัวแทนประกันที่พร้อมจะดูแลและปกป้องคุณและครอบครัวในทุกสถานการณ์" },
      { property: "og:url", content: "https://aiaprimeone.com" },
      { property: "og:type", content: "website" }
    ];

    metaTags.forEach(tag => {
      const metaElement = document.createElement("meta");
      metaElement.setAttribute("property", tag.property);
      metaElement.setAttribute("content", tag.content);
      document.head.appendChild(metaElement);
    });

  },

  setup() {

    const drawer = ref(false);
    const open = ref(false); // List group state
   

    onMounted(() => {

    });

    // Clean up the resize listener when the component is unmounted
    onUnmounted(() => {

    });



    return {
      drawer,
      open,
    };
  },
  computed: {
  },
  data() {
    return {

    };

  },

  methods: {
    navigateToPage() {
      this.$router.push('/cal-premium'); // Ensure your router is set up for this path
    },
  }


};
</script>


<style scoped>
.fixed-bottom {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.v-list-group {
  position: relative;
  z-index: 1;
}
</style>