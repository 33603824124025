<template>
  <v-container fluid class="pa-0">
    <v-row class=" mt-5 pa-3" justify="center" no-gutters>
      <v-col cols="12" md="6" sm="12">
        <div v-intersect="findPlan" class="content">

          <transition name="scroll-right" >
            <h2 v-if="showFindPlan" class="text-primary text-center">ค้นหาแผนประกันสุขภาพที่เหมาะสำหรับคุณ</h2>
          </transition>

        </div>
      </v-col>
    </v-row>
    <v-row class="pa-3 " justify="center" no-gutters>

      <v-col cols="12" md="6" sm="12">

        <v-form @submit.prevent="fetchInsurancePremium">
          <v-select v-model="selectedGender" :items="genders" item-value="gender_id" item-title="gender_name"
            label="เลือกเพศ" outlined return-object />

          <v-select v-model="selectedOccupation" :items="occupations" item-value="occupation_id"
            item-title="occupation_name" label="เลือกอาชีพ" outlined return-object />

          <v-text-field v-model="age" label="อายุ" type="text" outlined dense required
            v-bind="numericInputAttributes" />

          <v-select v-model="selectedSubTypes" :items="uniqueSubTypes" label="เลือกแผนประกัน (เลือกได้หลายแผน)" multiple
            closable-chips chips item-color="primary">
            <!-- <template v-slot:chip="{item}">
                  <v-chip
                  color="primary"
                  :text="item.name"
                  ></v-chip>
                </template>
-->

          </v-select>

          <v-btn type="submit" color="primary">ดูแผนทั้งหมด</v-btn>
        </v-form>
        <div class="mt-3 text-caption">
          <p>*ค่าเบี้ยยังไม่รวมประกันชีวิตหลัก การทำประกันเสริมสุขภาพ ต้องทำควบคู่กับประกันชีวิตหลัก</p>
        </div>
      </v-col>
    </v-row>
    <div ref="cardSection">
      <v-row v-if="insurancePremiums.length" class="mt-5 pa-3 bg-lightgray" no-gutters > 

        <v-col v-for="plan in insurancePremiums" :key="plan.premium_id" cols="12" md="3" sm="6" class="pa-3">
          <v-card color="primary" elevation="16" prepend-icon="mdi-file-document">
            <template v-slot:title>
              <div>
                <span class="font-weight-white">คุ้มครอง {{ formatMoney(plan.coverage_amount) }} บาท</span>
              </div>
            </template>
            <template v-slot:subtitle>
              <div>
                <span class="text-white">แผน {{ plan.insurance_type }} - {{ plan.insurance_sub_type }}</span>
              </div>
            </template>
            <v-card-text class="bg-secondary py-2">
              เบี้ย {{ formatMoney(plan.premium_amount) }} บาท

            </v-card-text>
            <v-card-actions class="bg-secondary">
              <v-checkbox hide-details class="shrink mr-2" :label="'เปรียบเทียบแผน'" :value="plan.premium_id"
                @change="handleCompare(plan)" color="primary" density="compact" />
            </v-card-actions>
            <!-- Button to open form -->
            <!--v-btn @click="toggleForm(plan)">Register plan</v-btn-->

          </v-card>
        </v-col>

      </v-row>
    </div>
    <div ref="compareSection">
      <v-row v-if="transformedData.length" class="pa-3 mt-5" no-gutters>
        <v-col cols="12">
          
          <v-table>
            <thead>
              <tr>
                <th class="font-weight-black bg-primary">หมวด</th>
                <th class="font-weight-black  bg-primary">คำอธิบาย</th>
                <!-- Plan ID columns for each selected plan -->
                <th class="text-center header-cell  bg-primary" v-for="(plan, index) in selectedPlans" :key="'header' + index">
                  <div class="header-content">
                    <span class="font-weight-black">แผน {{ plan.insurance_sub_type }} <br> คุ้มครอง {{
                      formatMoney(plan.coverage_amount) }}</span>
                    <v-btn small color="secondary" @click="toggleForm(plan)" class="plan-button">สนใจแผนนี้</v-btn>
                  </div>
                </th>

              </tr>
            </thead>
            <tbody>
              <!-- Display each category as a row -->
              <tr v-for="(data, index) in transformedData" :key="'row' + index">
                <td>{{ data.category }}</td>
                <td class="information_description">{{ data.description }}</td>
                <!-- Display coverage for each selected plan under the current category -->
                <td v-for="(coverage, planIndex) in data.coverages" :key="'cell' + planIndex + index">
                  {{ coverage || '-' }}
                </td>
              </tr>
              <tr>
                <td class="font-weight-black"></td>
                <td class="font-weight-black"></td>
                <!-- Plan ID columns for each selected plan -->
                <td class="text-center header-cell" v-for="(plan, index) in selectedPlans" :key="'header' + index">
                  <div class="header-content">
                    <v-btn small color="primary" @click="toggleForm(plan)" class="plan-button">สนใจแผนนี้</v-btn>
                  </div>
                </td>
              </tr>
            </tbody>
          </v-table>
        
        </v-col>

      </v-row>
    </div>

    <v-row v-if="activePlan" class="mt-5 bg-lightgray pa-3" justify="center" no-gutters>
      <v-col cols="12" md="6">
        <div ref="contactSection" v-intersect="addContact">
          <transition name="scroll-right">
          <h3 v-if="showAddContact" class="text-primary">กรอกข้อมูลสำหรับติดต่อกลับ</h3>
        </transition>
          <v-form ref="contactForm" v-on:submit.prevent="submitContactForm">
            <v-text-field v-model="mobileNo" label="เบอร์โทรศัพท์" :rules="[requiredRule]"
              v-bind="numericInputAttributes" outlined dense required></v-text-field>
            <v-text-field v-model="lineId" label="ไลน์ (ถ้ามี)"></v-text-field>
            <span class="text-weight-bold mb-2">ช่วงเวลาที่ต้องการให้ติดต่อ</span>
            <v-radio-group v-model="freePeriod" color="primary">

              <v-radio label="8.00-12.00" value="morning"></v-radio>
              <v-radio label="12.00-13.00" value="afternoon"></v-radio>
              <v-radio label="13.00-18.00" value="evening"></v-radio>
              <v-radio label="18.00-20.00" value="night"></v-radio>
            </v-radio-group>
            <span class="text-weight-bold mb-2">ช่องทางที่สะดวกให้ติดต่อ</span>
            <v-radio-group v-model="contactChannel" color="primary">
              <v-radio label="เบอร์โทรศัพท์" value="mobile"></v-radio>
              <v-radio label="ไลน์" value="line"></v-radio>
            </v-radio-group>
            <v-btn type="submit" color="primary">ส่งข้อมูล</v-btn>
          </v-form>
        </div>
      </v-col>
    </v-row>

  </v-container>

  <v-snackbar v-model="snackbar" :timeout="3000" top color="primary">
    {{ snackbarMessage }}
    <v-btn color="secondary" text @click="snackbar = false">ปิด</v-btn>
  </v-snackbar>
</template>

<script>
import { ref, onMounted,onUnmounted, watch, nextTick } from 'vue';
import axios from '../plugins/axios'; // Import the custom axios instance
import logoPath from '../assets/logo.png';

export default {
  mounted() {
    document.title = "AIA Prime One"
  },
  setup() {
    const drawer = ref('');
    const genders = ref([]);
    const occupations = ref([]);
    const selectedGender = ref(null);
    const selectedOccupation = ref(null);
    const age = ref(null);
    const insurancePlans = ref([]); // Initialize as an empty array to avoid undefined issues
    const insurancePremiums = ref([]);
    const activePlan = ref(null); // Currently active plan for form display
    const mobileNo = ref(''); // Mobile number input
    const freePeriod = ref(''); // Selected free period option
    const contactChannel = ref('');
    const lineId = ref('');
    const selectedSubTypes = ref([]);
    const planInformations = ref([]);
    const cardSection = ref(null);
    const compareSection = ref(null);
    const contactSection = ref(null);
    const contactForm = ref(null);
    const snackbar = ref(false); // Controls the visibility of the snackbar
    const snackbarMessage = ref(''); // Holds the message to display in the snackbar
    const requiredRule = (value) => (value !== undefined && value !== null && value !== '') ? true : 'This field is required';
    const phoneRule = (value) => /^\d{10}$/.test(value) ? true : 'Enter a valid 10-digit phone number';
    const target = ref(null);
    const appBarHeight = 64;
    


    const numericInputAttributes = {
      inputmode: "numeric",
      pattern: "[0-9]*"
    };

    const fetchGenders = async () => {
      console.log('Start fetch');
      try {
        const response = await axios.get('/gender');
        console.log('Fetched Genders:', response.data); // Log the data
        genders.value = response.data;
      } catch (error) {
        console.error('Error fetching genders:', error);
      }
    };

    const fetchOccupations = async () => {
      try {
        const response = await axios.get('/occupation');
        console.log('Fetched Occupations:', response.data); // Log the data
        occupations.value = response.data;
      } catch (error) {
        console.error('Error fetching occupations:', error);
      }
    };

    const fetchInsuranceInformation = async () => {
      try {
        const response = await axios.get('/insurance_information');
        console.log('Fetched Insurance Plans:', response.data); // Log the data
        insurancePlans.value = response.data;
      } catch (error) {
        console.error('Error fetching insurance information:', error);
      }
    };

    const fetchInsurancePremium = async () => {
      if (selectedGender.value && selectedOccupation.value && age.value) {
        console.log(selectedSubTypes.value)



        try {

          const planParams = new URLSearchParams();

          selectedSubTypes.value.forEach(subType => {
            planParams.append('insurance_sub_type', subType);
          });
          console.log(planParams)
          const planResponse = await axios.get('/insurance_information_by_sub_type', { params: planParams });
          planInformations.value = planResponse.data;

          const planIds = planInformations.value.map(subType => subType.id);
          console.log(planIds)

          const premiumParams = new URLSearchParams();
          /*premiumParams = {
            age: age.value,
            occupation_type_id: selectedOccupation.value.occupation_type_id,
            gender: selectedGender.value.gender_id
          };*/
          premiumParams.append('age', age.value);
          premiumParams.append('occupation_type_id', selectedOccupation.value.occupation_type_id);
          premiumParams.append('gender', selectedGender.value.gender_id);
          planIds.forEach(planId => {
            premiumParams.append('plan_ids', planId);
          });

          const premiumResponse = await axios.get('/insurance_premium', { params: premiumParams });
          insurancePremiums.value = premiumResponse.data;
          console.log("insurancePremiums", insurancePremiums.value)


        } catch (error) {
          console.error('Error fetching plan id / premium:', error);
        }
        await nextTick();
        if (cardSection.value) {
          const offsetPosition =
            cardSection.value.getBoundingClientRect().top +
            window.scrollY -
            appBarHeight; // Adjust scroll position by the height of the app-bar

          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          }); 
        } else {
          console.warn("Card section or app-bar ref is not available.");
        }
            

      }
    };

    const toggleForm = async (plan) => {
      activePlan.value = plan; // Show form for selected plan
      await nextTick();
      if (contactSection.value) {
        const offsetPosition =
          contactSection.value.getBoundingClientRect().top +
          window.scrollY -
          appBarHeight; // Adjust scroll position by the height of the app-bar

        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        }); 
      } else {
        console.warn("Card section or app-bar ref is not available.");
      }


    };
    const submitContactForm = async () => {
      // Check if the form is valid before submitting
      console.log(mobileNo.value);
      if (mobileNo.value) {
        await addCustomerContact();
      }
    };

    const addCustomerContact = async () => {
      if (!activePlan.value) {
        console.error("No active plan selected");
        return;
      }
      const requestData = {
        insurance_plan: activePlan.value, // Send the whole plan object as JSON
        customer_information: {
          occupation: selectedOccupation.value.occupation_name,
          gender: selectedGender.value.gender_name,
          age: age.value,
        },
        mobile_no: mobileNo.value,
        free_period: freePeriod.value,
        contact_channel: contactChannel.value,
        line_id: lineId.value,
      };

      try {
        const response = await axios.post('/customer_contact', requestData);
        console.log('Callback Request Saved:', response.data);

        // Clear form
        mobileNo.value = '';
        freePeriod.value = '';
        lineId.value = '';
        contactChannel.value = '';
        activePlan.value = null; // Hide the form after submission

        snackbarMessage.value = "ขอบคุณสำหรับข้อมูลการติดต่อ แล้วเราจะติดต่อไปครับ";
        snackbar.value = true;



      } catch (error) {
        console.error('Error saving callback request:', error);
      }
    };

    onMounted(() => {
      fetchGenders();
      fetchOccupations();
      fetchInsuranceInformation();
      fetchInsurancePremium();
    });
    onUnmounted(() => {
    });


    return {
      drawer,
      genders,
      occupations,
      selectedGender,
      selectedOccupation,
      age,
      insurancePlans,
      insurancePremiums,
      fetchInsurancePremium,
      activePlan,
      mobileNo,
      freePeriod,
      toggleForm,
      addCustomerContact,
      contactChannel,
      lineId,
      selectedSubTypes,
      cardSection,
      compareSection,
      contactSection,
      submitContactForm,
      requiredRule,
      phoneRule,
      snackbar,
      snackbarMessage,
      numericInputAttributes,
      appBarHeight
    };
  },
  computed: {
    uniqueSubTypes() {
      // Create an object to store unique insurance_sub_types
      const unique = [];
      const check = {};

      // Iterate over insurancePlans to group IDs by insurance_sub_type
      this.insurancePlans.forEach(plan => {
        if (plan.insurance_sub_type) {
          // If this sub-type doesn't exist in unique, create it
          if (!check[plan.insurance_sub_type]) {
            check[plan.insurance_sub_type] = { insurance_sub_type: plan.insurance_sub_type };
            unique.push(plan.insurance_sub_type)
          }
          // Push the plan ID into the corresponding sub-type
          //unique[plan.insurance_sub_type].id.push(plan.id);
        }
      });
      console.log(Object.values(unique))
      // Return an array of unique insurance sub types
      return Object.values(unique);
    },
  },
  data() {
    return {
      //insurancePremiums: [], // List of plans
      combinedData: [], // Combined data for the comparison table
      logo: logoPath,
      transformedData: {},   // Transformed data for the comparison table
      selectedPlans: [],     // Plans selected for comparison
      // banners1: [
      //   {
      //     src: '/banner-master.jpg',
      //     // title: 'Welcome to our site',
      //     // description: 'This is the first banner description',
      //   },
      //   {
      //     src: '/banner-agent.jpg',
      //     // title: 'Discover our products',
      //     // description: 'This is the second banner description',
      //   },

      // ]
      showFindPlan: false,
      showAddContact: false

    };

  },

  methods: {
    findPlan (isIntersecting, entries, observer) {
        // More information about these options
        // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        this.showFindPlan = isIntersecting
    },
    addContact (isIntersecting, entries, observer) {
        // More information about these options
        // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
        this.showAddContact = isIntersecting
    },

    formatMoney(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async handleCompare(plan) {
      
      const existingIndex = this.selectedPlans.findIndex((p) => p.premium_id === plan.premium_id);
      console.log("pid", this.selectedPlans)
      console.log("planid", plan.id)

      if (existingIndex === -1) {
        // If the plan is not already in selectedPlans, fetch its data and add it
        const fetchedPlan = await this.fetchComparisonData(plan);
        console.log("fetchplan", fetchedPlan)
        if (fetchedPlan) {
          this.selectedPlans.push(fetchedPlan);
        }
      } else {
        // If already present, remove it from the selectedPlans
        this.selectedPlans.splice(existingIndex, 1);
      }
      
      this.transformComparisonData();

      await nextTick();
      if (this.$refs.compareSection) {
          const offsetPosition =
            this.$refs.compareSection.getBoundingClientRect().top +
            window.scrollY -
            this.appBarHeight; // Adjust scroll position by the height of the app-bar
            console.log(this.appBarHeight);
          window.scrollTo({
            top: offsetPosition,
            behavior: 'smooth'
          }); 
        } else {
          console.warn("Card section or app-bar ref is not available.");
        }


    },

    async fetchComparisonData(plan) {
      try {
        const responseInformation = await axios.get('/insurance_information', {
          params: { plan_ids: plan.insurance_plan_id }
        });
        console.log("information");
        console.log(responseInformation);
        const insuranceInformation = responseInformation.data[0];

        const responsePremium = await axios.get('/insurance_premium_by_id', {
          params: { id: plan.premium_id }
        });
        console.log("premium");
        console.log(responsePremium);
        const insurancePremium = responsePremium.data[0];

        console.log(insuranceInformation.ipd_information.benefits);


        return {
          ...insurancePremium,
          ...insuranceInformation,
          benefits: this.mapBenefitsByCategory(insuranceInformation.ipd_information.benefits),
        };
      } catch (error) {
        console.error('Error fetching plan data:', error);
        return null;
      }
    },
    /*async fetchComparisonData(plan) {
      try {
        const informationParams = new URLSearchParams({ plan_ids: plan.insurance_plan_id });
        const responseInformation = await axios.get('/insurance_information', {params:informationParams});
        console.log('Fetched Insurance Information:', responseInformation.data); // Log the data
        const insuranceInformation = responseInformation.data[0];
 
        const premiumParams = new URLSearchParams({ id: plan.id });
        const responsePremium = await axios.get('/insurance_premium_by_id', {params:premiumParams});
        console.log('Fetched Insurance Plans:', responsePremium.data); // Log the data
        const insurancePremium = responsePremium.data[0];
        return {
          ...insurancePremium,
          ...insuranceInformation,
        };
 
      } catch (error) {
        console.error('Error fetching insurance information:', error);
      }
 
      try {
        const response = await fetch(`/api/compare/${planId}`);
        if (response.ok) {
          const data = await response.json();
          return data;
        } else {
          console.error("Error fetching comparison data");
        }
      } catch (error) {
        console.error("Error fetching comparison data", error);
      }
    },*/
    mapBenefitsByCategory(benefits) {
      return benefits.reduce((acc, benefit) => {
        // Group benefits by category for easier access in the table
        //const key = `${benefit.category} - ${benefit.description}`
        const key = `${benefit.category}`
        acc[key] = benefit.coverage;
        //console.log("acc")
        //console.log(acc)
        return acc;
      }, {});
    },
    /*mapBenefitsByCategory(benefits) {
      return Object.entries(benefits).reduce((acc, [category, coverage]) => {
        // Create a unique key by combining category and description
        const key = `${category} - ${coverage}`;
        acc[key] = coverage; // Store coverage under the combined key
        console.log("acc", acc); // Debugging log to inspect acc structure
        return acc;
      }, {});
    },*/
    /*transformComparisonData() {
      const categories = new Set();
      console.log("selected plan")
      console.log(this.selectedPlans)
      this.selectedPlans.forEach((plan) => {
        Object.keys(plan.benefits).forEach((category) => categories.add(category));
      });
 
      // Transform into an array of categories with coverage info from each plan
      console.log("transformedData")
     
      this.transformedData = Array.from(categories).map((category) => ({
        category,
        coverages: this.selectedPlans.map((plan) => plan.benefits[category]),
        description: this.selectedPlans.map((plan) => plan.benefits[category]),
      }));
      console.log(this.transformedData)
    },*/
    transformComparisonData() {
      const categories = new Set();
      console.log("Selected Plans:", this.selectedPlans);

      this.selectedPlans.forEach((plan) => {
        if (plan.benefits && typeof plan.benefits === 'object') {
          Object.keys(plan.benefits).forEach((category) => categories.add(category));
        } else {
          console.warn(`plan.benefits is not an object for plan ID: ${plan.id}`, plan.benefits);
        }
      });

      // Transform into an array of objects where each object represents a category with coverage and description
      this.transformedData = Array.from(categories).map((category) => {
        // Find the description for this category from the first selected plan
        const description = this.selectedPlans
          .map((plan) => {
            // Search within ipd_information.benefits array for the matching category
            if (Array.isArray(plan.ipd_information?.benefits)) {
              const benefit = plan.ipd_information.benefits.find(
                (benefit) => benefit.category === category
              );
              return benefit ? benefit.description : null;
            }
            return null;
          })
          .find((desc) => desc); // Use the first non-null description found

        return {
          category,
          description: description || 'No description available', // Default if no description is found
          coverages: this.selectedPlans.map((plan) => {
            if (plan.benefits && typeof plan.benefits === 'object') {
              return plan.benefits[category] || 'N/A'; // Get coverage or default to 'N/A'
            }
            return 'N/A'; // Default to 'N/A' if benefits is not an object
          }),
        };
      });

      console.log("Transformed Data:", this.transformedData);
    }

  },


};
</script>


<style scoped>
.v-card {
  margin-bottom: 1rem;
}

.header-cell {
  height: 200px;
  /* Set a fixed height for each header cell */
  /*display: flex;*/
  align-items: center;
  justify-content: center;
  padding-top:5px !important;
  padding-bottom:5px !important;
}

.header-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* Make header-content take the full height of the header cell */
  align-items: center;
  text-align: center;
}

.plan-button {
  margin-top: auto;
  /* Ensures the button sticks to the bottom */
  width: 100px;
  /* Optional: set a consistent width for the button */
  max-width: 100%;
}

.information_description {
  min-width: 150px;
}

.fade-in-enter-active,
.fade-in-leave-active {
  transition: opacity 3s ease;
  /* Adjust speed and easing here */
}

.fade-in-enter-from,
.fade-in-leave-to {
  opacity: 0;
}

.scroll-right-enter-active {
  transition: all 0.6s ease-out;
}

.scroll-right-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.scroll-right-enter-from,
.scroll-right-leave-to {
  transform: translateX(40px);
  opacity: 0;
}
.animated-component.fade-enter-from,
.animated-component.zoom-enter-from {
    transition: none;
}

/* Fade animation */
.fade-enter-active,
.fade-leave-active {
    transition: opacity 300ms ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

/* Zoom animation */
.zoom-enter-active,
.zoom-leave-active {
    transition: transform 300ms ease;
}

.zoom-enter-from,
.zoom-leave-to {
    transform: scale(0.9);
}
</style>